<template>
  <div class="header mb-0" id="marqii-header">
    <div class="header-body">
      <div class="d-flex align-items-center justify-content-between">
        <!-- title -->
        <div class="ml-2">
          <h1 class="header-title ml-3">Marqii</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarqiiHeader',
}
</script>
