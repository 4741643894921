<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <MarqiiViews :clickOut="clickOutEnabled" />
    </div>
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import MarqiiViews from '../components/Modules/Marqii/MarqiiViews'
import MainContent from '../components/MainContent/MainContent'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'InsightsPage',
  components: {
    MainContent,
    MarqiiViews,
  },
  async mounted() {
    trackEvent(this.$intercom, 'Marqii')
  },
  computed: {
    ...CompanyModule.mapGetters(['activeCompany']),
    clickOutEnabled() {
      return this.activeCompany?.settings?.marqiiClickOutEnabled
    },
  },
}
</script>
