<template>
  <div>
    <MarqiiHeader />
    <MarqiiOffstate v-if="!clickOut" />
  </div>
</template>

<script>
import MarqiiHeader from './MarqiiHeader.vue'
import MarqiiOffstate from './MarqiiOffstate'
import Config from '@/config'

export default {
  name: 'MarqiiViews',
  props: {
    // clickout is for redirecting to marqii's site
    clickOut: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MarqiiHeader,
    MarqiiOffstate,
  },
  mounted() {
    if (this.clickOut) {
      window.parent.postMessage({ openUrl: 'https://app.marqii.com/' }, Config.baseAppUrl)
      this.$router.back()
    }
  },
}
</script>
