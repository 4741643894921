<template>
  <div class="card col-12 mt-7">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <h2>Manage your online reputation and listings in one place</h2>

          <p class="text-muted mb-4">
            Click below to schedule some time with our team to learn more
          </p>

          <a
            href="https://calendly.com/ovationupjake/listings-and-review-management-with-ovation"
            class="btn btn-lg btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarqiiOffState',
}
</script>
